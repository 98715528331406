<template>
  <div class="count-down">
    <slot v-if="useSlot"></slot>
    <span v-else>{{ formattedTime }}</span>
  </div>
</template>

<script>
const SECOND = 1000;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;

function simpleTick (fn) {
  return setTimeout(fn, 30)
}
export default {
  name: 'countDown',
  props: {
    useSlot: Boolean,
    millisecond: Boolean,
    time: {
      type: Number
    },
    format: {
      type: String,
      default: 'HH:mm:ss'
    },
    autoStart: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      timeData: this.parseTimeData(0),
      formattedTime: '0'
    }
  },
  watch: {
    time: {
      handler () {
        this.reset()
      },
      deep: true
    }
  },
  created () {
    this.reset()
  },
  methods: {
    padZero (num, targetLength = 2) {
      let str = num + '';
      while (str.length < targetLength) {
        str = '0' + str
      }
      return str
    },
    parseTimeData (time) {
      const days = Math.floor(time / DAY);
      const hours = Math.floor((time % DAY) / HOUR);
      const minutes = Math.floor((time % HOUR) / MINUTE);
      const seconds = Math.floor((time % MINUTE) / SECOND);
      const milliseconds = Math.floor(time % SECOND);
      return {
        days,
        hours,
        minutes,
        seconds,
        milliseconds
      }
    },
    parseFormat (format, timeData) {
      const { days } = timeData;
      let { hours, minutes, seconds, milliseconds } = timeData;
      if (format.indexOf('DD') === -1) {
        hours += days * 24
      } else {
        format = format.replace('DD', this.padZero(days))
      }
      if (format.indexOf('HH') === -1) {
        minutes += hours * 60
      } else {
        format = format.replace('HH', this.padZero(hours))
      }
      if (format.indexOf('mm') === -1) {
        seconds += minutes * 60
      } else {
        format = format.replace('mm', this.padZero(minutes))
      }
      if (format.indexOf('ss') === -1) {
        milliseconds += seconds * 1000
      } else {
        format = format.replace('ss', this.padZero(seconds))
      }
      return format.replace('SSS', this.padZero(milliseconds, 3))
    },
    isSameSecond (time1, time2) {
      return Math.floor(time1 / 1000) === Math.floor(time2 / 1000)
    },
    // 开始
    start () {
      if (this.counting) {
        return
      }
      this.counting = true;
      this.endTime = Date.now() + this.remain;
      this.tick()
    },
    // 暂停
    pause () {
      this.counting = false;
      clearTimeout(this.tid)
    },
    // 重置
    reset () {
      this.pause();
      this.remain = this.time;
      this.setRemain(this.remain);
      if (this.autoStart) {
        this.start()
      }
    },
    tick () {
      if (this.millisecond) {
        this.microTick()
      } else {
        this.macroTick()
      }
    },
    microTick () {
      this.tid = simpleTick(() => {
        this.setRemain(this.getRemain());
        if (this.remain !== 0) {
          this.microTick()
        }
      })
    },
    macroTick () {
      this.tid = simpleTick(() => {
        const remain = this.getRemain();
        if (!this.isSameSecond(remain, this.remain) || remain === 0) {
          this.setRemain(remain)
        }
        if (this.remain !== 0) {
          this.macroTick()
        }
      })
    },
    getRemain () {
      return Math.max(this.endTime - Date.now(), 0)
    },
    setRemain (remain) {
      this.remain = remain;
      const timeData = this.parseTimeData(remain);
      if (this.useSlot) {
        this.$emit('change', timeData)
      }
      this.formattedTime = this.parseFormat(this.format, timeData);
      if (remain === 0) {
        this.pause();
        this.$emit('finish')
      }
    }
  },
  computed: {},
  destroyed () {
    clearTimeout(this.tid);
    this.tid = null
  }
}
</script>

<style scoped>
  /*@formatter:off*/
  .count-down{color:#323233;font-size:14px;line-height:20px;display: inline;}
  /*@formatter:on*/
</style>
